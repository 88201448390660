import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'

const Cdd = require('../svgs/brands/cdd.svg')
const Bbc = require('../svgs/brands/bbc.svg')
const Itv = require('../svgs/brands/itv.svg')
const Pearson = require('../svgs/brands/pearson.svg')
const Sky = require('../svgs/brands/sky-cinema.svg')
const Swt = require('../svgs/brands/swt.svg')
const Airbnb = require('../svgs/brands/airbnb.svg')
const Twitter = require('../svgs/brands/twitter.svg')

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "brighton-pier.jpg" }) {
      ...heroImage
    }
  }
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="amillionmonkeys | Web + Mobile Development with Laravel, React and VueJS"
      description="amillionmonkeys is a software development agency specializing in PHP, Laravel, React, and Vue.js development"
    />
    <StaticQuery
      query={query}
      render={data => (
        <React.Fragment>
          <Hero image={data.hero.childImageSharp.fluid}>
            <div className="border border-yellow skew">
              <h1 className="bg-yellow font-impact px-4 py-2 text-white text-5xl md:text-10xl leading-none">
                amillionmonkeys
              </h1>
              <h2 className="font-impact px-4 py-3 text-white text-3xl md:text-5xl leading-none text-center">
                making the web work better
              </h2>
            </div>
          </Hero>
          <div className="bg-yellow bg-stamp text-300 text-white py-16  px-4  pb-24 ">
            <div className="max-w-md m-auto text-2xl font-serif font-light text-center">
              <p>
                amillionmonkeys is a tiny Brighton-based, full-stack development studio. We partner with business owners
                and creative agencies to build digital products that make an impact. We are good communicators, easy to
                work with, and turn projects around quickly.
              </p>
            </div>
          </div>
          <div className="bg-grey-light text-grey-darker py-16  px-4 ">
            <div className="container text-2xl font-serif font-light text-center">
              <h2 className="font-impact text-4xl">Our Clients</h2>
              <p className="mx-auto max-w-lg mb-4">
                We've worked with some pretty cool clients over the past seven years. Here's a selection:
              </p>
              <ul className="list-reset flex flex-wrap items-center justify-center">
                <li className="py-4 px-6">
                  <Airbnb className="h-12 w-auto" />
                </li>
                <li className="py-4 px-6">
                  <Bbc className="h-12 w-auto" />
                </li>
                <li className="py-4 px-6">
                  <Itv className="h-16 w-auto" />
                </li>
                <li className="py-4 px-6">
                  <Cdd className="h-12 w-auto" />
                </li>
                <li className="py-4 px-6">
                  <Pearson className="h-16 w-auto" />
                </li>
                <li className="py-4 px-6">
                  <Sky className="h-12 w-auto" />
                </li>
                <li className="py-4 px-6">
                  <Swt className="h-16 w-auto" />
                </li>
                <li className="py-4 px-6">
                  <Twitter className="h-16 w-auto" />
                </li>
              </ul>
            </div>
          </div>
        </React.Fragment>
      )}
    />
  </Layout>
)

export default IndexPage
